var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{ref:"modal",attrs:{"id":"add-user","size":"lg","title":"Add User","ok-title":"Add","cancel-disabled":_vm.disableConfirmButtons,"ok-disabled":_vm.disableConfirmButtons,"no-close-on-backdrop":true},on:{"ok":_vm.handleOk,"show":_vm.onReset}},[_c('loading',{attrs:{"active":_vm.isLoading,"loader":"spinner","color":"#20A8D8","is-full-page":false},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-container',{attrs:{"fluid":""}},[_c('div',[_c('b-tabs',[_c('b-tab',{attrs:{"title":"Primary Information","active":""}},[_c('b-row',{staticClass:"my-2"},[_c('b-col',{attrs:{"lg":"6","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Company"}},[_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:('selectRequired'),expression:"'selectRequired'"}],staticClass:"style-chooser",attrs:{"name":"Company","label":"text","options":_vm.companyOptions,"reduce":(company) => company.value},scopedSlots:_vm._u([{key:"no-options",fn:function({ search, searching }){return [(searching)?[_vm._v(" No results found for "),_c('em',[_c('strong',[_vm._v(_vm._s(search))])])]:_c('em',{style:({ opacity: 0.5 })},[_vm._v(" Start typing to search for a company ")])]}}]),model:{value:(_vm.selCompany),callback:function ($$v) {_vm.selCompany=$$v},expression:"selCompany"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('Company')),expression:"errors.has('Company')"}],staticClass:"help-block"},[_vm._v(_vm._s(_vm.errors.first('Company')))])],1)],1)],1),_c('b-row',{staticClass:"my-2"},[_c('b-col',{attrs:{"lg":"4","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"First Name","label-for":"firstName","description":""}},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({
										required: true,
										regex: _vm.nameRegex,
									}),expression:"{\n\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\tregex: nameRegex,\n\t\t\t\t\t\t\t\t\t}"}],attrs:{"name":"First Name","type":"text","placeholder":"First Name","maxlength":"30"},model:{value:(_vm.form.firstName),callback:function ($$v) {_vm.$set(_vm.form, "firstName", $$v)},expression:"form.firstName"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('First Name')),expression:"errors.has('First Name')"}],staticClass:"help-block"},[_vm._v(_vm._s(_vm.errors.first('First Name')))])],1)],1),_c('b-col',{attrs:{"lg":"4","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Middle Name","label-for":"middleName","description":""}},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({
											required: false,
											regex: _vm.nameRegex,
										}),expression:"{\n\t\t\t\t\t\t\t\t\t\t\trequired: false,\n\t\t\t\t\t\t\t\t\t\t\tregex: nameRegex,\n\t\t\t\t\t\t\t\t\t\t}"}],attrs:{"name":"Middle Name","type":"text","placeholder":"Middle Name","maxlength":"30"},model:{value:(_vm.form.middleName),callback:function ($$v) {_vm.$set(_vm.form, "middleName", $$v)},expression:"form.middleName"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('Middle Name')),expression:"errors.has('Middle Name')"}],staticClass:"help-block"},[_vm._v(_vm._s(_vm.errors.first('Middle Name')))])],1)],1),_c('b-col',{attrs:{"lg":"4","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Last Name","label-for":"lastName","description":""}},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({
										required: true,
										regex: _vm.nameRegex,
									}),expression:"{\n\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\tregex: nameRegex,\n\t\t\t\t\t\t\t\t\t}"}],attrs:{"name":"Last Name","type":"text","placeholder":"Last Name","maxlength":"30"},model:{value:(_vm.form.lastName),callback:function ($$v) {_vm.$set(_vm.form, "lastName", $$v)},expression:"form.lastName"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('Last Name')),expression:"errors.has('Last Name')"}],staticClass:"help-block"},[_vm._v(_vm._s(_vm.errors.first('Last Name')))])],1)],1)],1),_c('b-row',{staticClass:"my-2"},[_c('b-col',{attrs:{"lg":"4","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Job Position","label-for":"position","description":""}},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({
										required: true,
										regex: _vm.positionRegex,
									}),expression:"{\n\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\tregex: positionRegex,\n\t\t\t\t\t\t\t\t\t}"}],attrs:{"name":"Position","type":"text","placeholder":"Job Position","maxlength":"60"},model:{value:(_vm.form.position),callback:function ($$v) {_vm.$set(_vm.form, "position", $$v)},expression:"form.position"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('Position')),expression:"errors.has('Position')"}],staticClass:"help-block"},[_vm._v(_vm._s(_vm.errors.first('Position')))])],1)],1),_c('b-col',{attrs:{"lg":"4","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Employee No.","label-for":"employeeNo","description":""}},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({
											required: false,
											regex: _vm.employeeNoRegex,
										}),expression:"{\n\t\t\t\t\t\t\t\t\t\t\trequired: false,\n\t\t\t\t\t\t\t\t\t\t\tregex: employeeNoRegex,\n\t\t\t\t\t\t\t\t\t\t}"}],staticClass:"numFont",attrs:{"name":"Employee No","type":"text","placeholder":"Employee No.","maxlength":"12"},model:{value:(_vm.form.employeeNo),callback:function ($$v) {_vm.$set(_vm.form, "employeeNo", $$v)},expression:"form.employeeNo"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('Employee No')),expression:"errors.has('Employee No')"}],staticClass:"help-block"},[_vm._v(_vm._s(_vm.errors.first('Employee No')))])],1)],1),_c('b-col',{attrs:{"lg":"4","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Access Type","label-for":"type","description":""}},[_c('b-form-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"mb-3",attrs:{"name":"Type","options":_vm.userTypes},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('Type')),expression:"errors.has('Type')"}],staticClass:"help-block"},[_vm._v(_vm._s(_vm.errors.first('Type')))])],1)],1),_c('b-col',{attrs:{"lg":"4","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Contact No.","label-for":"contactNo","description":""}},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({
											required: _vm.contactNoIsRequired,
											regex: _vm.contactNoRegex,
										}),expression:"{\n\t\t\t\t\t\t\t\t\t\t\trequired: contactNoIsRequired,\n\t\t\t\t\t\t\t\t\t\t\tregex: contactNoRegex,\n\t\t\t\t\t\t\t\t\t\t}"}],staticClass:"numFont",attrs:{"name":"Contact No","type":"text","placeholder":"+639xxxxxxxxx","maxlength":"13","autocomplete":"off"},model:{value:(_vm.form.contactNo),callback:function ($$v) {_vm.$set(_vm.form, "contactNo", $$v)},expression:"form.contactNo"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('Contact No')),expression:"errors.has('Contact No')"}],staticClass:"help-block"},[_vm._v(" "+_vm._s('Contact No. must follow this format: +639xxxxxxxxx')+" ")])],1)],1)],1)],1),_c('b-tab',{attrs:{"title":"Account"}},[_c('b-row',{staticClass:"my-2"},[_c('b-col',{attrs:{"lg":"6","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Email Address","label-for":"emailAddress","description":"Email address will be used as the username of this user's account"}},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({
											required: true,
											regex: _vm.emailRegex,
										}),expression:"{\n\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\tregex: emailRegex,\n\t\t\t\t\t\t\t\t\t\t}"}],attrs:{"name":"Email Address","type":"email","placeholder":"Email Address","autocomplete":"off"},model:{value:(_vm.form.emailAddress),callback:function ($$v) {_vm.$set(_vm.form, "emailAddress", $$v)},expression:"form.emailAddress"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('Email Address')),expression:"errors.has('Email Address')"}],staticClass:"help-block"},[_vm._v(_vm._s(_vm.errors.first('Email Address')))])],1)],1)],1),_c('b-row',{staticClass:"my-2"},[_c('b-col',{attrs:{"lg":"6","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Password","label-for":"password"}},[_c('b-input-group',[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({
											required: true,
											regex: _vm.passwordRegex,
										}),expression:"{\n\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\tregex: passwordRegex,\n\t\t\t\t\t\t\t\t\t\t}"}],attrs:{"name":"Password","placeholder":"Password","type":_vm.showPassword ? 'text' : 'password',"autocomplete":"new-password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('b-input-group-append',[_c('b-button',{on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}},[_c('span',[_c('b-icon',{attrs:{"icon":_vm.showPassword ? 'eye-slash' : 'eye'}})],1)])],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('Password')),expression:"errors.has('Password')"}],staticClass:"help-block"},[_vm._v(_vm._s(_vm.passwordErrorMsg))])],1)],1)],1),_c('b-col',{attrs:{"lg":"6","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Confirm Password","label-for":"confirmPassword","description":""}},[_c('b-input-group',[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({
												required: true,
												regex: _vm.passwordRegex,
											}),expression:"{\n\t\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\t\tregex: passwordRegex,\n\t\t\t\t\t\t\t\t\t\t\t}"}],attrs:{"name":"Confirm Password","placeholder":"Confirm Password","type":_vm.showConfirmPassword ? 'text' : 'password',"autocomplete":"new-confirm-password"},model:{value:(_vm.form.confirmPassword),callback:function ($$v) {_vm.$set(_vm.form, "confirmPassword", $$v)},expression:"form.confirmPassword"}}),_c('b-input-group-append',[_c('b-button',{on:{"click":function($event){_vm.showConfirmPassword = !_vm.showConfirmPassword}}},[_c('span',[_c('b-icon',{attrs:{"icon":_vm.showConfirmPassword ? 'eye-slash' : 'eye'}})],1)])],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('Confirm Password')),expression:"errors.has('Confirm Password')"}],staticClass:"help-block"},[_vm._v(_vm._s(_vm.passwordErrorMsg))])],1)],1)],1)],1)],1),_c('b-tab',{attrs:{"title":"Subscription"}},[_c('b-row',{staticClass:"my-2"},[_c('b-col',{attrs:{"lg":"4","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Status"}},[_c('b-form-radio-group',{attrs:{"options":_vm.statusOptions,"name":"isActive"},model:{value:(_vm.form.isActive),callback:function ($$v) {_vm.$set(_vm.form, "isActive", $$v)},expression:"form.isActive"}})],1)],1),_c('b-col',{attrs:{"lg":"4","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Has Notification?"}},[_c('b-form-radio-group',{attrs:{"options":_vm.yesNoOptions},model:{value:(_vm.form.hasNotif),callback:function ($$v) {_vm.$set(_vm.form, "hasNotif", $$v)},expression:"form.hasNotif"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"lg":"4","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Enable 2-Way Auth?"}},[_c('b-form-radio-group',{attrs:{"options":_vm.yesNoOptions},model:{value:(_vm.form.has2WayAuth),callback:function ($$v) {_vm.$set(_vm.form, "has2WayAuth", $$v)},expression:"form.has2WayAuth"}})],1)],1)],1)],1)],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }