<template>
	<b-modal id="add-user" size="lg" title="Add User" ref="modal" ok-title="Add" @ok="handleOk" @show="onReset"
		:cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<div>
					<b-tabs>
						<b-tab title="Primary Information" active>
							<b-row class="my-2">
								<b-col lg="6" md="12" sm="12">
									<b-form-group label="Company">
										<v-select name="Company" class="style-chooser" label="text"
											:options="companyOptions" :reduce="(company) => company.value"
											v-model="selCompany" v-validate="'selectRequired'">
											<template v-slot:no-options="{ search, searching }">
												<template v-if="searching">
													No results found for
													<em>
														<strong>{{ search }}</strong>
													</em>
												</template>
												<em :style="{ opacity: 0.5 }" v-else>
													Start typing to search for a company
												</em>
											</template>
										</v-select>
										<span v-show="errors.has('Company')" class="help-block">{{
											errors.first('Company')
										}}</span>
									</b-form-group>
								</b-col>
							</b-row>

							<b-row class="my-2">
								<b-col lg="4" md="12" sm="12">
									<b-form-group label="First Name" label-for="firstName" description>
										<b-form-input name="First Name" type="text" v-model="form.firstName" v-validate="{
											required: true,
											regex: nameRegex,
										}" placeholder="First Name" maxlength="30" />
										<span v-show="errors.has('First Name')" class="help-block">{{
											errors.first('First Name')
										}}</span>
									</b-form-group>
								</b-col>
								<b-col lg="4" md="12" sm="12">
									<b-form-group label="Middle Name" label-for="middleName" description>
										<b-form-input name="Middle Name" type="text" v-model="form.middleName"
											v-validate="{
												required: false,
												regex: nameRegex,
											}" placeholder="Middle Name" maxlength="30" />
										<span v-show="errors.has('Middle Name')" class="help-block">{{
											errors.first('Middle Name')
										}}</span>
									</b-form-group>
								</b-col>
								<b-col lg="4" md="12" sm="12">
									<b-form-group label="Last Name" label-for="lastName" description>
										<b-form-input name="Last Name" type="text" v-model="form.lastName" v-validate="{
											required: true,
											regex: nameRegex,
										}" placeholder="Last Name" maxlength="30" />
										<span v-show="errors.has('Last Name')" class="help-block">{{
											errors.first('Last Name')
										}}</span>
									</b-form-group>
								</b-col>
							</b-row>

							<b-row class="my-2">
								<b-col lg="4" md="6" sm="12">
									<b-form-group label="Job Position" label-for="position" description>
										<b-form-input name="Position" type="text" v-model="form.position" v-validate="{
											required: true,
											regex: positionRegex,
										}" placeholder="Job Position" maxlength="60" />
										<span v-show="errors.has('Position')" class="help-block">{{
											errors.first('Position')
										}}</span>
									</b-form-group>
								</b-col>
								<b-col lg="4" md="6" sm="12">
									<b-form-group label="Employee No." label-for="employeeNo" description>
										<b-form-input name="Employee No" type="text" class="numFont"
											v-model="form.employeeNo" v-validate="{
												required: false,
												regex: employeeNoRegex,
											}" placeholder="Employee No." maxlength="12" />
										<span v-show="errors.has('Employee No')" class="help-block">{{
											errors.first('Employee No')
										}}</span>
									</b-form-group>
								</b-col>
								<b-col lg="4" md="6" sm="12">
									<b-form-group label="Access Type" label-for="type" description>
										<b-form-select name="Type" v-model="form.type" v-validate="'required'"
											:options="userTypes" class="mb-3" />
										<span v-show="errors.has('Type')" class="help-block">{{
											errors.first('Type')
										}}</span>
									</b-form-group>
								</b-col>
								<b-col lg="4" md="6" sm="12">
									<b-form-group label="Contact No." label-for="contactNo" description>
										<b-form-input name="Contact No" type="text" class="numFont"
											v-model="form.contactNo" v-validate="{
												required: contactNoIsRequired,
												regex: contactNoRegex,
											}" placeholder="+639xxxxxxxxx" maxlength="13" autocomplete="off" />
										<span v-show="errors.has('Contact No')" class="help-block">
											{{ 'Contact No. must follow this format: +639xxxxxxxxx' }}
										</span>
									</b-form-group>
								</b-col>
							</b-row>
						</b-tab>

						<b-tab title="Account">
							<b-row class="my-2">
								<b-col lg="6" md="6" sm="12">
									<b-form-group label="Email Address" label-for="emailAddress"
										description="Email address will be used as the username of this user's account">
										<b-form-input name="Email Address" type="email" v-model="form.emailAddress"
											v-validate="{
												required: true,
												regex: emailRegex,
											}" placeholder="Email Address" autocomplete="off" />
										<span v-show="errors.has('Email Address')" class="help-block">{{
											errors.first('Email Address')
										}}</span>
									</b-form-group>
								</b-col>
							</b-row>

							<b-row class="my-2">
								<b-col lg="6" md="6" sm="12">
									<b-form-group label="Password" label-for="password">
										<b-input-group>
											<b-form-input name="Password" v-model="form.password" v-validate="{
												required: true,
												regex: passwordRegex,
											}" placeholder="Password" :type="showPassword ? 'text' : 'password'" autocomplete="new-password" />
											<b-input-group-append>
												<b-button @click="showPassword = !showPassword">
													<span>
														<b-icon :icon="showPassword ? 'eye-slash' : 'eye'" />
													</span>
												</b-button>
											</b-input-group-append>
											<span v-show="errors.has('Password')" class="help-block">{{
												passwordErrorMsg
											}}</span>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col lg="6" md="6" sm="12">
									<b-form-group label="Confirm Password" label-for="confirmPassword" description>
										<b-input-group>
											<b-form-input name="Confirm Password" v-model="form.confirmPassword"
												v-validate="{
													required: true,
													regex: passwordRegex,
												}" placeholder="Confirm Password" :type="showConfirmPassword ? 'text' : 'password'" autocomplete="new-confirm-password" />
											<b-input-group-append>
												<b-button @click="showConfirmPassword = !showConfirmPassword">
													<span>
														<b-icon :icon="showConfirmPassword ? 'eye-slash' : 'eye'" />
													</span>
												</b-button>
											</b-input-group-append>
											<span v-show="errors.has('Confirm Password')" class="help-block">{{
												passwordErrorMsg
											}}</span>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
						</b-tab>

						<b-tab title="Subscription">
							<b-row class="my-2">
								<b-col lg="4" md="6" sm="12">
									<b-form-group label="Status">
										<b-form-radio-group v-model="form.isActive" :options="statusOptions"
											name="isActive" />
									</b-form-group>
								</b-col>

								<b-col lg="4" md="6" sm="12">
									<b-form-group label="Has Notification?">
										<b-form-radio-group v-model="form.hasNotif" :options="yesNoOptions" />
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col lg="4" md="6" sm="12">
									<b-form-group label="Enable 2-Way Auth?">
										<b-form-radio-group v-model="form.has2WayAuth" :options="yesNoOptions" />
									</b-form-group>
								</b-col>
							</b-row>
						</b-tab>
					</b-tabs>
				</div>
			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { UserUtil } from '@/utils/userutil';
import { ValidationUtil } from '@/utils/validationUtil';

// API & DAO
import userApi from '@/api/userApi';
import companyDAO from '@/database/companies';

// Others
import EventBus from '@/shared/event-bus';
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'add-user',
	components: {
		Loading,
	},
	props: {
		companyOptions: {
			type: Array,
			required: true,
		},
		allCompaniesObj: {
			type: Object,
			required: true,
		},
		allUsersObj: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			form: { ...UserUtil.getDefaultUserObj() },
			userTypes: config.userTypes,
			yesNoOptions: [
				{ text: 'YES', value: true },
				{ text: 'NO', value: false },
			],
			statusOptions: config.statusOptions,

			selCompany: { ...config.companyDefaultValue },
			loggedUser: this.$store.getters.loggedUser,
			// Check for loader
			isLoading: false,

			showPassword: false,
			showConfirmPassword: false,
		};
	},
	computed: {
		contactNoIsRequired() {
			return this.form.has2WayAuth;
		},
		disableConfirmButtons() {
			return this.isLoading;
		},
		name() {
			return this.form.firstName + ' ' + this.form.lastName;
		},
		nameRegex() {
			return config.nameRegex;
		},
		contactNoRegex() {
			return config.contactNoRegex;
		},
		emailRegex() {
			return config.emailRegex;
		},
		positionRegex() {
			return config.positionRegex;
		},
		employeeNoRegex() {
			return config.employeeNoRegex;
		},
		passwordRegex() {
			return config.passwordRegex;
		},
		passwordErrorMsg() {
			return config.passwordErrorMsg;
		}
	},
	methods: {
		async handleOk(evt) {
			evt.preventDefault();

			// show loading indicator
			this.isLoading = true;

			this.form = {
				...this.form,
				company: this.selCompany.name,
				companyId: this.selCompany.id
			};

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				this.isLoading = false;
				return;
			} else if (ValidationUtil.objectHasField('employeeNo', this.form.employeeNo, this.allUsersObj)) {
				this.$toaster.warning('Employee number ' + this.form.employeeNo + ' already exists.');
				this.isLoading = false;
				return;
			} else if (UserUtil.exceedMaximum(this.allCompaniesObj[this.selCompany.id], this.allUsersObj)) {
				this.$toaster.warning('You have exceeded the allowed number of active users for this company.');
				this.isLoading = false;
				return;
			} else if (!UserUtil.allowManagerAccount(this.form, this.allUsersObj)) {
				this.$toaster.warning('You can only have 3 managers accounts within a company.');
				this.isLoading = false;
				return;
			} else if (this.selCompany.isActive === "false") {
				this.$toaster.warning('You cannot add users to an inactive company.');
				this.isLoading = false;
				return;
			} else if (this.form.password !== this.form.confirmPassword) {
				this.$toaster.warning('Password and Confirm Password must be the same.');
				this.isLoading = false;
				return;
			}

			const { data } = await userApi.validatePassword(this.form.password, this.loggedUser.id);
			if (!data.isSuccess) {
				this.$toaster.warning(data.message);
				this.isLoading = false;
				return;
			}

			await this.handleSubmit();
		},
		getUserObject(param) {
			return {
				firstName: param.firstName,
				middleName: param.middleName,
				lastName: param.lastName,
				company: param.company,
				companyId: param.companyId,
				position: param.position,
				employeeNo: param.employeeNo,
				type: param.type,
				emailAddress: param.emailAddress,
				password: param.password,
				contactNo: param.contactNo,
				isActive: param.isActive,
				isNewUser: true,
				isPasswordExpired: false,
				passwordExpirationDate: 0,
				hasNotif: param.hasNotif,
				has2WayAuth: param.has2WayAuth,
				companyAccess: param.companyAccess,
				dateCreated: DateUtil.getCurrentTimestamp(),
				createdBy: this.loggedUser.id,
				dateUpdated: DateUtil.getCurrentTimestamp(),
				updatedBy: this.loggedUser.id,
			};
		},
		async handleSubmit() {
			// show loading indicator
			this.isLoading = true;

			this.processFormValues();

			try {
				let userObj = this.getUserObject(this.form);
				let { data } = await userApi.addUser(
					userObj,
					this.loggedUser.id,
					DateUtil.getCurrentTimestamp()
				);

				if (data.isSuccess) {
					// Update company setup status
					await companyDAO.updateCompanySetupStatus({
						companyId: this.selCompany.id,
						data: {
							hasActiveUser: true,
						},
					});

					this.$toaster.success(
						`User "${this.name}" was created successfully.`
					);
					EventBus.$emit('onCloseSaveUser', data.user);
					this.$refs.modal.hide();
				} else {
					let error = data.errors[0];
					if (
						(!_.isEmpty(error.code)
							&& error.code === 'auth/email-already-exists')
						|| error.code === 'auth/phone-number-already-exists') {
						this.$toaster.error(error.message);
					} else {
						this.$toaster.error(error);
					}
				}

			} catch (_error) {
				this.$toaster.error(`Error creating user "${this.name}". Please try again.`);
			}

			// hide loading indicator
			this.isLoading = false;
		},
		processFormValues() {
			// Removes excess whitespace
			this.form.firstName = ValidationUtil.removeExcessWhiteSpace(
				this.form.firstName
			);
			this.form.lastName = ValidationUtil.removeExcessWhiteSpace(
				this.form.lastName
			);
			this.form.middleName = ValidationUtil.removeExcessWhiteSpace(
				this.form.middleName
			);
			this.form.position = ValidationUtil.removeExcessWhiteSpace(
				this.form.position
			);

			// Assign the selected company to the user
			this.form.company = this.selCompany.name;
			this.form.companyId = this.selCompany.id;

			// update company access first
			this.form.companyAccess = UserUtil.getDefaultCompanyAccess(this.form, this.allCompaniesObj);
		},

		onReset() {
			/* Reset our form values */
			this.form = { ...UserUtil.getDefaultUserObj() };

			// init company
			this.selCompany = { ...config.companyDefaultValue };

			this.showPassword = false;
			this.showConfirmPassword = false;

			// reset validation
			this.isLoading = false;
			this.$validator.reset();
			this.errors.clear();
		},
	},
};
</script>